const DRAFT_PENDING = 10
const DRAFT_ACCEPTED = 20
const DRAFT_REJECTED = 30
const PRELIMINARY_OFFER_SUBMITTED = 40
const PRELIMINARY_OFFER_ACCEPTED = 50
const FINAL_OFFER_SUBMITTED = 60
const FINAL_OFFER_ACCEPTED = 70
const PLANNED = 80
const READY = 90
const ONGOING = 100
const OFFHIRE = 110
const COMPLETED = 120


const projectStatuses = [
  {
    value: 10,
    label: "Pending client request",
    color: "yellow",
  },
  {
    value: 20,
    label: "Accepted client request",
    color: "blue",
  },
  {
    value: 30,
    label: "Rejected",
    color: "red",
  },
  {
    value: 40,
    label: "Pending client approval",
    color: "yellow",
  },
  {
    value: 50,
    label: "Preliminary offer accepted",
    color: "green",
  },
  {
    value: 60,
    label: "Pending client signature",
    color: "yellow",
  },
  {
    value: 70,
    label: "Final offer accepted",
    color: "purple",
  },
  {
    value: 80,
    label: "Operations planned",
    color: "blue",
  },
  {
    value: 90,
    label: "Ready to start",
    color: "blue",
  },
  {
    value: 100,
    label: "On going",
    color: "green",
  },
  {
    value: 110,
    label: "Operations finished",
    color: "blue",
  },
  {
    value: 120,
    label: "Closed",
    color: "grey",
  },
]

const statusLabel = (key) => {
  const status = projectStatuses.find(f => f.value == key)
  return status == undefined ? "N/A" : status.label
}

const statusColor = (key) => {
  const status = projectStatuses.find(f => f.value == key)
  return status == undefined ? "N/A" : status.color
}

const projectCategories = [
  {
    "value": "crew_transfer",
    "label": "Crew transfer",
  },
  {
    "value": "offshore_operations",
    "label": "Offshore operations",
  },
  {
    "value": "coastal_sw_operations",
    "label": "Coastal SW operations",
  },
  {
    "value": "subsea_survey_operations",
    "label": "Subsea survey operations",
  },
  {
     "value": "simops_operations",
     "label": "Simops operations",
  },
  {
    "value": "specific_operation",
    "label": "Specific operation",
  },
  {
    "value": "seabed_survey",
    "label": "Seabed survey",
  },
  {
    "value": "rov_cvi_survey",
    "label": "ROV CVI survey",
  },
  {
    "value": "rov_tss_survey",
    "label": "ROV TSS survey",
  },
  {
    "value": "installation",
    "label": "Installation",
  },
  {
    "value": "offshore_xx_maintenance_support",
    "label": "Offshore XX maintenance support",
  },
  {
     "value": "subsea_operations",
     "label": "Subsea operations",
  },
]

const categoryLabel = (key) => {
  const category = projectCategories.find(f => f.value == key)
  return category == undefined ? "N/A" : category.label
}

const useProjectStates = () => {
  const hasState = (project, state) => {
    return project.state === state
  }
  const stateGTE = (project, state) => {
    return project.state >= state
  }
  const stateLTE = (project, state) => {
    return project.state <= state
  }
  return {
    DRAFT_PENDING,
    DRAFT_ACCEPTED,
    DRAFT_REJECTED,
    PRELIMINARY_OFFER_SUBMITTED,
    PRELIMINARY_OFFER_ACCEPTED,
    FINAL_OFFER_SUBMITTED,
    FINAL_OFFER_ACCEPTED,
    PLANNED,
    READY,
    ONGOING,
    OFFHIRE,
    COMPLETED,
    hasState,
    stateGTE,
    stateLTE,
  }
}

export {
  DRAFT_PENDING,
  DRAFT_ACCEPTED,
  DRAFT_REJECTED,
  PRELIMINARY_OFFER_SUBMITTED,
  PRELIMINARY_OFFER_ACCEPTED,
  FINAL_OFFER_SUBMITTED,
  FINAL_OFFER_ACCEPTED,
  PLANNED,
  READY,
  ONGOING,
  OFFHIRE,
  COMPLETED,
  projectStatuses,
  projectCategories,
  statusLabel,
  statusColor,
  categoryLabel,
  useProjectStates,
}
