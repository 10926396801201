<template>
  <t-server-form
    :action-url="actionUrl"
    :server-errors="serverErrors"
    :validate-form="validateForm"
  >
    <slot />
    <template #form>
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
        <div class="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10 grid grid-cols-1 sm:grid-cols-2 gap-4">
          <t-input
            id="first_name"
            v-model="form.first_name"
            :validator="v$.first_name"
            name="first_name"
            label="First name"
          />
          <t-input
            id="last_name"
            v-model="form.last_name"
            :validator="v$.last_name"
            name="last_name"
            label="Last name"
          />
          <t-select
            id="nationality"
            v-model="form.nationality"
            :validator="v$.nationality"
            :options="countries"
            name="nationality"
            label="Nationality"
            placeholder="-- Select your nationality --"
          />
          <t-input
            id="email"
            v-model="form.email"
            :validator="v$.email"
            type="email"
            name="email"
            label="E-mail"
          />
          <t-radio-group
            v-model="form.gender"
            :validator="v$.gender"
            :options="[{value: 'men', label: 'Men'},{value: 'women', label: 'Women'}]"
            label="Gender"
            name="gender"
            required
          />
          <t-input
            id="address"
            v-model="form.address"
            :validator="v$.address"
            name="address"
            label="Address"
            class="col-span-full"
          />
          <t-phone-input
            id="mobile_phone"
            v-model="form.mobile_phone"
            :validator="v$.mobile_phone"
            name="mobile_phone"
            label="Mobile phone"
          />
          <t-phone-input
            id="land_phone"
            v-model="form.land_phone"
            :validator="v$.land_phone"
            name="land_phone"
            label="Land phone"
          />
          <t-input
            id="company_name"
            v-model="form.company_name"
            :validator="v$.company_name"
            name="company_name"
            label="Company name"
          />
          <t-input
            id="company_business_id"
            v-model="form.company_business_id"
            :validator="v$.company_business_id"
            name="company_business_id"
            label="Business id"
            help="Add your SIRET number or your company id available in your country."
          />
          <t-select
            id="position"
            v-model="form.position"
            :validator="v$.position"
            name="position"
            label="Job position"
            :options="positions"
            placeholder="-- Select your position --"
          />

          <t-input
            id="password1"
            v-model="form.password1"
            :validator="v$.password1"
            type="password"
            name="password1"
            label="Password"
            class="col-start-1"
          />
          <t-input
            id="password2"
            v-model="form.password2"
            :validator="v$.password2"
            type="password"
            name="password2"
            label="Password confirmation"
          />
          <a
            :href="previousUrl"
            class="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 place-self-center"
          >
            Previous step
          </a>
          <a-button
            type="submit"
            block
            variant="primary"
          >
            Next
          </a-button>
        </div>
      </div>
    </template>
  </t-server-form>
</template>

<script>
import { countryList as countries } from "@/data/countries"
import { jobPositions as positions } from "@/data/positions"
import TServerForm from "@/shared/ui/TServerForm"
import TInput from "@/shared/ui/TInput"
import TSelect from "@/shared/ui/TSelect"
import TPhoneInput from "@/shared/ui/TPhoneInput"
import TRadioGroup from "@/shared/ui/TRadioGroup"
import { email, requiredIf } from "@vuelidate/validators"
import { required, password, useFormValidator } from "@/utils/validators"

export default {
  name: "SignUpClient",
  components: {
    TServerForm,
    TInput,
    TSelect,
    TRadioGroup,
    TPhoneInput,
  },
  props: {
    serverErrors: {
      type: String,
      default: "",
    },
    actionUrl: {
      type: String,
      default: "",
    },
    previousUrl: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { ...initialValues } = JSON.parse(document.getElementById("form_data").textContent)
    const form = {
      email: "",
      first_name: "",
      last_name: "",
      password1: "",
      password2: "",
      address: "",
      gender: null,
      nationality: null,
      position: null,
      land_phone: "",
      mobile_phone: "",
      company_name: "",
      company_business_id: "",
      ...initialValues,
    }
    const rules = {
      email: { required, email },
      first_name: { required },
      last_name: { required },
      password1: { required, password },
      password2: { required, password },
      address: { required },
      gender: { required },
      nationality: { required },
      position: { required },
      mobile_phone: {
        requiredIf: requiredIf(form.land_phone),
      },
      land_phone: {
        requiredIf: requiredIf(form.mobile_phone),
      },
      company_name: { required },
      company_business_id: { required },
    }
    return {
      countries,
      positions,
      ...useFormValidator(form, rules),
    }
  },
}
</script>
