<template>
  <div class="rootClass">
    <label
      v-if="label || $slots.label"
      :for="$attrs.name"
      class="block text-sm font-medium text-gray-700"
    >
      <slot name="label">{{ label }}</slot>
    </label>
    <div class="mt-1 relative">
      <div class="max-w-lg">
        <div class="relative flex items-start">
          <div class="flex items-center h-5">
            <input
              v-model="value"
              type="checkbox"
              :true-value="values[1]"
              :false-value="values[0]"
              class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-800 rounded"
            >
          </div>
          <div class="ml-3 text-sm">
            <label class="font-medium text-gray-700"><slot /></label>
          </div>
        </div>
      </div>
    </div>
    <p
      class="mt-1 text-sm"
      :class="{
        'text-gray-500': !hasError,
        'text-red-600': hasError
      }"
    >
      {{ hasError ? firstError : help }}
    </p>
  </div>
</template>

<script>
import { computed } from "vue"
import { inputProps } from "vue-batteries"
import { useFieldValidator, validatorProps } from "@/utils/validators"
export default {
  name: "TCheckbox",
  props: {
    modelValue: {
      type: [Number, Boolean, String, Array, Object],
      required: true,
    },
    values: {
      type: Array,
      default: () => [false, true],
    },
    ...inputProps,
    ...validatorProps,
  },
  emits: ["update:modelValue"],
  setup (props, context) {
    const value = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        context.emit("update:modelValue", value)
      },
    })
    return {
      value,
      ...useFieldValidator(props.validator, context.attrs.name),
    }
  },
}
</script>
