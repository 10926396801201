<template>
  <t-server-form
    :action-url="actionUrl"
    :server-errors="serverErrors"
    :fields-form-errors="['password1']"
    :validate-form="validateForm"
  >
    <slot />
    <template #form>
      <t-input
        id="password1"
        v-model="form.password1"
        :validator="v$.password1"
        label="New password"
        type="password"
        name="password1"
      />
      <t-input
        id="password2"
        v-model="form.password2"
        :validator="v$.password2"
        type="password"
        name="password2"
        label="New password confirmation"
      />

      <div class="mt-6">
        <span class="block w-full rounded-md shadow-sm">
          <button
            type="submit"
            class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
          >
            Change password
          </button>
        </span>
      </div>
    </template>
  </t-server-form>
</template>

<script>
import TServerForm from "@/shared/ui/TServerForm"
import TInput from "@/shared/ui/TInput"
import { required, password, useFormValidator } from "@/utils/validators"

export default {
  name: "PasswordResetForm",
  components: {
    TServerForm,
    TInput,
  },
  props: {
    serverErrors: {
      type: String,
      default: "",
    },
    actionUrl: {
      type: String,
      default: "",
    },
  },
  setup () {
    return {
      ...useFormValidator(
        { password1: "", password2: "" },
        { password1: { required, password }, password2: { required, password } },
      ),
    }
  },
}
</script>
