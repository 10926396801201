export const jobPositions = [
  {
    value: "ceo",
    label: "CEO",
  },
  {
    value: "manager",
    label: "Manager",
  },
  {
    value: "technician",
    label: "Technician",
  },
  {
    value: "other",
    label: "Other",
  },
]
