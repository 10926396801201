<template>
  <div>
    <label
      v-if="label || $slots.label"
      :for="$attrs.name"
      class="block text-sm font-medium text-gray-700"
    >
      <slot name="label">{{ label }}</slot>
    </label>
    <div class="mt-1 relative">
      <div class="max-w-lg space-y-4">
        <div
          v-for="option in normalizedOptions"
          :key="option.value"
          class="relative flex items-start"
        >
          <div class="flex items-center h-5">
            <input
              type="radio"
              :checked="isSelected(option)"
              :name="$attrs.name"
              :value="option.value"
              class="h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
              @change="selectOption(option)"
            >
          </div>
          <div class="ml-3 text-sm">
            <label
              for="comments"
              class="font-medium text-gray-700"
            >{{ option.label }}</label>
          </div>
        </div>
      </div>
    </div>
    <p
      class="mt-1 text-sm"
      :class="{
        'text-gray-500': !hasError,
        'text-red-600': hasError
      }"
    >
      {{ hasError ? firstError : help }}
    </p>
  </div>
</template>

<script>
import { useSelect, inputProps } from "vue-batteries"
import { useFieldValidator, validatorProps } from "@/utils/validators"
import { toRefs } from "vue"

export default {
  name: "TRadioGroup",
  props: {
    options: {
      type: [Array, Object],
      required: true,
    },
    modelValue: {
      type: [Number, Boolean, String, Array, Object, null],
      required: true,
    },
    ...inputProps,
    ...validatorProps,
  },
  setup (props, context) {
    const { modelValue, options } = toRefs(props)
    return {
      ...useSelect(context, modelValue, options),
      ...useFieldValidator(props.validator, context.attrs.name),
    }
  },
}
</script>
