<template>
  <t-server-form
    :action-url="actionUrl"
    :server-errors="serverErrors"
    :validate-form="validateForm"
  >
    <slot />
    <template #form>
      <t-input
        v-model="form.first_name"
        :validator="v$.first_name"
        name="first_name"
        label="First name"
      />
      <t-input
        v-model="form.last_name"
        :validator="v$.last_name"
        name="last_name"
        label="Last name"
        class="mt-6"
      />
      <t-select
        v-model="form.nationality"
        :validator="v$.nationality"
        :options="countryList"
        name="nationality"
        label="Nationality"
        class="mt-6"
      />
      <t-input
        v-model="form.address"
        :validator="v$.address"
        name="address"
        label="Address"
        class="mt-6"
      />
      <t-radio-group
        v-model="form.gender"
        :validator="v$.gender"
        :options="[{value: 'men', label: 'Men'},{value: 'women', label: 'Women'}]"
        label="Gender"
        name="gender"
        required
        class="col-span-4"
      />
      <t-phone-input
        v-model="form.mobile_phone"
        :validator="v$.mobile_phone"
        name="mobile_phone"
        label="Mobile phone"
        class="mt-6"
      />
      <t-phone-input
        v-model="form.land_phone"
        :validator="v$.land_phone"
        name="land_phone"
        label="Land phone"
        class="mt-6"
      />
      <t-input
        v-model="form.company_name"
        :validator="v$.company_name"
        name="company_name"
        label="Company name"
        class="mt-6"
      />
      <t-input
        v-model="form.company_business_id"
        :validator="v$.company_name"
        name="company_business_id"
        label="Business id"
        class="mt-6"
        help="Add your SIRET number or your company id available in your country."
      />
      <t-input
        v-model="form.email"
        :validator="v$.email"
        type="email"
        name="email"
        label="E-mail"
        class="mt-6"
      />
      <t-input
        v-model="form.password1"
        :validator="v$.password1"
        type="password"
        name="password1"
        label="Password"
        class="mt-6"
      />
      <t-input
        v-model="form.password2"
        :validator="v$.password2"
        type="password"
        name="password2"
        label="Password confirmation"
        class="mt-6"
      />
      <a-button
        type="submit"
        block
        variant="primary"
        class="mt-6"
      >
        Sign up
      </a-button>
      <div class="mt-6">
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center text-sm leading-5">
            <a
              :href="loginUrl"
              class="px-2 text-gray-500 bg-white cursor-pointer hover:text-indigo-600"
            >
              Did you already sign up ? Sign in
            </a>
          </div>
        </div>
      </div>
    </template>
  </t-server-form>
</template>

<script>
import { countryList } from "@/data/countries"
import TServerForm from "@/shared/ui/TServerForm"
import TInput from "@/shared/ui/TInput"
import TSelect from "@/shared/ui/TSelect"
import TPhoneInput from "@/shared/ui/TPhoneInput"
import TRadioGroup from "@/shared/ui/TRadioGroup"
import { email, requiredUnless } from "@vuelidate/validators"
import { required, password, useFormValidator } from "@/utils/validators"
import { computed } from "vue"

export default {
  name: "SignUpProfessional",
  components: {
    TServerForm,
    TInput,
    TSelect,
    TRadioGroup,
    TPhoneInput,
  },
  props: {
    serverErrors: {
      type: String,
      default: "",
    },
    actionUrl: {
      type: String,
      default: "",
    },
    loginUrl: {
      type: String,
      default: "",
    },
  },
  setup () {
    const { ...initialValues } = JSON.parse(document.getElementById("form_data").textContent)
    const form = {
      email: "",
      first_name: "",
      last_name: "",
      password1: "",
      password2: "",
      address: "",
      gender: null,
      nationality: null,
      land_phone: "",
      mobile_phone: "",
      company_name: "",
      company_business_id: "",
      ...initialValues,
    }
    const rules = computed(() => ({
      email: { required, email },
      first_name: { required },
      last_name: { required },
      password1: { required, password },
      password2: { required, password },
      address: { required },
      gender: { required },
      nationality: { required },
      mobile_phone: {
        requiredUnless: requiredUnless(form.land_phone != ""),
      },
      land_phone: {
        requiredUnless: requiredUnless(form.mobile_phone != ""),
      },
      company_name: { required },
      company_business_id: { required },
    }))
    return {
      ...useFormValidator(form, rules),
      countryList,
    }
  },
}
</script>
