<template>
  <div class="max-w-2xl mx-auto bg-white shadow sm:rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <h3 class="flex items-center text-lg font-medium leading-6 text-gray-900">
        <svg
          class="w-8 h-8 text-primary-variant"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        ><path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        /></svg>
        <span class="ml-4">{{ title }}</span>
      </h3>
      <div class="max-w-xl mt-2 text-sm leading-5 text-gray-500">
        <slot />
      </div>
      <div class="mt-3 text-sm leading-5">
        <slot name="action">
          <a
            :href="actionLink"
            class="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500"
          >
            {{ actionName }}
          </a>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardMessage",
  props: {
    title: {
      type: String,
      default: "Card Title",
    },
    actionLink: {
      type: String,
      default: "#",
    },
    actionName: {
      type: String,
      default: "Action Link",
    },
  },
}
</script>

<style>

</style>
