<template>
  <t-server-form
    :action-url="actionUrl"
    :server-errors="serverErrors"
    :validate-form="validateForm"
  >
    <slot />
    <template #form>
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
        <div class="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10 grid grid-cols-1 sm:grid-cols-2 gap-4">
          <t-input
            id="name"
            v-model="form.name"
            :validator="v$.name"
            name="name"
            label="Title"
            placeholder="Project title"
            class="col-span-full"
          />
          <t-select
            v-model="form.category"
            :validator="v$.category"
            name="category"
            label="Project Category"
            placeholder="-- Select a category --"
            class="col-span-full"
            :options="projectCategories"
          />
          <t-input
            id="estimated_duration"
            v-model="form.estimated_duration"
            :validator="v$.estimated_duration"
            name="estimated_duration"
            label="Project duration estimate (days)"
            type="number"
          />
          <t-input
            id="desired_start_date"
            v-model="form.desired_start_date"
            :validator="v$.desired_start_date"
            name="desired_start_date"
            label="Desired start date"
            type="date"
          />
          <t-input
            id="on_location"
            v-model="form.on_location"
            :validator="v$.on_location"
            name="on_location"
            label="Onshore location"
            placeholder="56100 Lorient, France"
          />
          <t-input
            id="off_location"
            v-model="form.off_location"
            :validator="v$.off_location"
            name="off_location"
            label="Offshore location"
            placeholder="56100 Lorient, France"
          />
          <t-textarea
            id="description"
            v-model="form.description"
            :validator="v$.description"
            name="description"
            class="col-span-full"
            rows="6"
            placeholder="Vous pouvez indiquer dans cet encadré les résultats à atteindre et/ou les moyens recherchés, ainsi que toute information utile à la bonne compréhension de votre besoin et au bon dimensionnement de la solution SAAS."
          >
            <template #label>
              <div class="flex items-center">
                <span>Description</span>
                <a-button
                  class="ml-2"
                  leading-icon="information-circle"
                  type="button"
                  @click="showDescriptionTooltip=true"
                />
              </div>
            </template>
          </t-textarea>
          <t-textarea
            id="expected_deliverables"
            v-model="form.expected_deliverables"
            :validator="v$.expected_deliverables"
            name="expected_deliverables"
            class="col-span-full"
            rows="5"
            placeholder="Vous pouvez indiquer dans cet encadré les livrables attendus. "
          >
            <template #label>
              <div class="flex items-center">
                <span>Expected deliverables</span>
                <a-button
                  class="ml-2"
                  leading-icon="information-circle"
                  type="button"
                  @click="showExpectedDeliverablesTooltip=true"
                />
              </div>
            </template>
          </t-textarea>
          <div class="col-start-2 text-right">
            <a-button
              type="submit"
              variant="primary"
            >
              Next
            </a-button>
          </div>
        </div>
      </div>
    </template>

    <!-- description tooltip with a modal -->
    <modal
      v-model="showDescriptionTooltip"
      name="description-tooltip"
      variant="success"
      :wide="true"
      :icon="false"
      :secondary="false"
      title=""
      @primary="showDescriptionTooltip = false"
    >
      <template #subcontent>
        <div class="text-sm">
          <p>
            <b>Nature des opérations</b>: étude de l’environnement marin, opérations offshore, services d’inspection sous-marine, campagne d’essai d’équipements, support maritime pour des opérations de maintenance… Indiquer une durée.
          </p>
          <br>
          <p>
            <b>Précisions sur les moyens :</b>

            Pour les navires : dimensions, vitesse (éco/transit), capacités d’accueil à bord (personnel et matériel), capacités de levage, énergie à délivrer (tension / puissance), équipement de pont, surface de pont, salles, interfaces pour des capteurs ou des équipements particuliers, outillage et aides à l’installation… Préciser si contrainte existante /pavillon et équipage.

            Pour les équipements de prospection : performances des véhicules, performances des capteurs (profondeur, charges utiles, résolution pour l’instrumentation, définitions pour les capteurs photo/video…).

            Pour les équipements de positionnement (GPS, DGPS, USBL) : précision souhaitée.
          </p>
          <br>
          <p>
            <b>Précisions sur la zone :</b>

            Coordonnées, distance à la côte, profondeur, règles d’accès…
          </p>

          <br>
          <p>
            <b>Précisions sur l’organisation :</b>

            Présenter la chronologie / la période souhaitées.

            Préciser ports souhaités de mobilisation/démobilisation.

            Préciser préavis souhaités.

            Préciser formule souhaite (tarifs journaliers, offre forfaitaire, carburant inclus/exclus, frais portuaires inclus/exclus…).
          </p>

          <br>
          <p>
            <b>Conditions d’environnement :</b>

            Préciser limites souhaitées si connues (vent, état de mer, courant, visibilité…).
          </p>


          <br>
          <p>
            <b>HSQE :</b>

            Préciser attendus particuliers (politique compagnie, règlements propres au site / au projet…).
          </p>


          <br>
          <p>
            <b>Assistance technique et support gestion de projet :</b>

            Préciser les services attendus : ingénierie, assistance pour l’obtention des autorisations, chef de projet/operations manager lors des opérations en mer…
          </p>

          <br>
          <p>
            <b>Divers :</b>

            Restauration Client O/N.

            Confidentialité.
          </p>
        </div>
      </template>
    </modal>

    <!-- expected deliverables tooltip with a modal -->
    <modal
      v-model="showExpectedDeliverablesTooltip"
      name="expected-deliverables-tooltip"
      variant="success"
      :wide="true"
      :icon="false"
      :secondary="false"
      title=""
      @primary="showExpectedDeliverablesTooltip = false"
    >
      <template #subcontent>
        <div class="text-sm">
          <p>
            <b>Opérations offshore :</b>
            <ul>
              <li>Daily Progress Reports.</li>

              <li>Trace navire (fichier shp).</li>

              <li>Bunker survey in / off hire (par compagnie, par un tiers).</li>

              <li>Compte-rendu d’opérations.</li>
            </ul>
          </p>
          <br>
          <p>
            <b>Services de survey :</b>
            <ul>
              <li>Log-book.</li>

              <li>Rapport de levé.</li>

              <li>Certificats de calibration des équipements.</li>

              <li>Rapport de mobilisation (offsets...).</li>

              <li>Patch test.</li>

              <li>Données brutes / traitées.</li>

              <li>Fichier de sondes.</li>

              <li>Modèle numérique de terrain (geotiff).</li>

              <li>Mosaïque sonar.</li>

              <li>Profils SVP.</li>

              <li>Livrables cartographiques (type, nombre, échelle, découpage...).</li>

              <li>Fiches d’obstructions.</li>
            </ul>
          </p>
          <br>
          <p>
            <b>Services d’inspection ROV :</b>

            <ul>
              <li>Log-Event.</li>

              <li>Rapport d’inspection.</li>

              <li>Certificats de calibration des équipements.</li>

              <li>Rapport de mobilisation (offsets...).</li>

              <li>Spin check test.</li>

              <li>Vidéo SD avec overlay.</li>

              <li>Photos et vidéos HD.</li>

              <li>Traces ROV (fichier shp).</li>

              <li>Profils SVP.</li>

              <li>Livrables cartographiques (extrait trace ROV sur fond de carte, obstructions sur fond de carte...).</li>

              <li>Liste des obstructions.</li>
            </ul>
          </p>
        </div>
      </template>
    </modal>
  </t-server-form>
</template>

<script>
import TServerForm from "@/shared/ui/TServerForm"
import TInput from "@/shared/ui/TInput"
import TSelect from "@/shared/ui/TSelect"
import TTextarea from "@/shared/ui/TTextarea"
import Modal from "@/shared/ui/Modal"
import { required, useFormValidator } from "@/utils/validators"
import { projectCategories } from "@/data/projects"
import { ref } from "vue"

export default {
  name: "ClientStep1",
  components: {
    TServerForm,
    TInput,
    TSelect,
    TTextarea,
    Modal,
  },
  props: {
    serverErrors: {
      type: String,
      default: "",
    },
    actionUrl: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { category, ...initialValues } = JSON.parse(document.getElementById("form_data").textContent)
    const form = {
      name: "",
      category: category != "" ? category : null,
      desired_start_date: "",
      estimated_duration: "",
      on_location: "",
      off_location: "",
      description: "",
      expected_deliverables: "",
      ...initialValues,
    }
    const rules = {
      name: { required },
      category: { required },
      desired_start_date: { required },
      estimated_duration: { required },
      on_location: { required },
      off_location: { required },
      description: { required },
      expected_deliverables: {},
    }
    const showDescriptionTooltip = ref(false)
    const showExpectedDeliverablesTooltip = ref(false)
    return {
     ...useFormValidator(form, rules),
      projectCategories,
      showDescriptionTooltip,
      showExpectedDeliverablesTooltip,
    }
  },
}
</script>
