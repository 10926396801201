<template>
  <div class="p-4 bg-red-100 rounded-md">
    <div class="flex">
      <div class="flex-shrink-0">
        <!-- Heroicon name: x-circle -->
        <svg
          class="w-5 h-5 text-red-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="ml-3 text-sm leading-5 text-red-700">
        <p v-if="typeof details === 'string'">
          {{ details }}
        </p>
        <p v-else-if="details.length == 1">
          {{ details[0] }}
        </p>
        <ul
          v-for="(detail, index) in details"
          v-else
          :key="`alert-error-${index}`"
          class="pl-5 list-disc"
        >
          <li class="mb-1">
            {{ detail }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AlertError",
  props: {
    details: {
      type: [String, Array],
      default: "",
    },
  },
}
</script>
