<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <h2 class="mt-6 text-3xl font-extrabold leading-9 text-center text-white">
      Complete your profile
    </h2>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <t-server-form
      :action-url="actionUrl"
      :server-errors="serverErrors"
      :validate-form="validateForm"
    >
      <slot />
      <template #form>
        <div class="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
          <t-input
            id="password1"
            v-model="form.password1"
            :validator="v$.password1"
            type="password"
            name="password1"
            label="Password"
          />
          <t-input
            id="password2"
            v-model="form.password2"
            :validator="v$.password2"
            type="password"
            name="password2"
            label="Password confirmation"
            class="mt-6"
          />
        </div>

        <div class="px-4 py-8 mt-6 bg-white shadow sm:rounded-lg sm:px-10">
          <t-input
            id="first_name"
            v-model="form.first_name"
            :validator="v$.first_name"
            name="first_name"
            label="First name"
          />
          <t-input
            id="last_name"
            v-model="form.last_name"
            :validator="v$.last_name"
            name="last_name"
            label="Last name"
            class="mt-6"
          />
          <t-select
            id="nationality"
            v-model="form.nationality"
            :validator="v$.nationality"
            :options="countries"
            name="nationality"
            label="Nationality"
            placeholder="-- Select your nationality --"
            class="mt-6"
          />
          <t-input
            id="address"
            v-model="form.address"
            :validator="v$.address"
            name="address"
            label="Address"
            class="mt-6"
          />
          <t-input
            id="email"
            :model-value="email"
            type="email"
            name="email"
            label="E-mail"
            disabled
            class="mt-6"
          />
          <t-phone-input
            id="mobile_phone"
            v-model="form.mobile_phone"
            :validator="v$.mobile_phone"
            name="mobile_phone"
            label="Mobile phone"
            class="mt-6"
          />
          <t-phone-input
            id="land_phone"
            v-model="form.land_phone"
            :validator="v$.land_phone"
            name="land_phone"
            label="Land phone"
            class="mt-6"
          />
          <t-input
            id="company_name"
            v-model="form.company_name"
            :validator="v$.company_name"
            name="company_name"
            label="Company name"
            class="mt-6"
          />
          <t-input
            id="company_business_id"
            v-model="form.company_business_id"
            :validator="v$.company_business_id"
            name="company_business_id"
            label="Business id"
            class="mt-6"
            help="Add your SIRET number or your company id available in your country."
          />
          <div class="mt-6">
            <span class="block w-full rounded-md shadow-sm">
              <button
                type="submit"
                class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
              >
                Save
              </button>
            </span>
          </div>
        </div>
      </template>
    </t-server-form>
  </div>
</template>

<script>
import TServerForm from "@/shared/ui/TServerForm"
import TInput from "@/shared/ui/TInput"
import TSelect from "@/shared/ui/TSelect"
import TPhoneInput from "@/shared/ui/TPhoneInput"
import { countryList as countries } from "@/data/countries"
import { jobPositions as positions } from "@/data/positions"
import { requiredIf } from "@vuelidate/validators"
import { required, password, useFormValidator } from "@/utils/validators"
export default {
  name: "ProfileCompletion",
  components: {
    TServerForm,
    TInput,
    TSelect,
    TPhoneInput,
  },
  props: {
    serverErrors: {
      type: String,
      default: "",
    },
    invitation: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    actionUrl: {
      type: String,
      default: "",
    },
  },
  setup () {
    const { ...initialValues } = JSON.parse(document.getElementById("form_data").textContent)
    const form = {
      first_name: "",
      last_name: "",
      password1: "",
      password2: "",
      address: "",
      nationality: null,
      land_phone: "",
      position: null,
      mobile_phone: "",
      company_name: "",
      company_business_id: "",
      ...initialValues,
    }
    const rules = {
      first_name: { required },
      last_name: { required },
      password1: { required, password },
      password2: { required, password },
      address: { required },
      nationality: { required },
      mobile_phone: {
        requiredIf: requiredIf(form.land_phone),
      },
      land_phone: {
        requiredIf: requiredIf(form.mobile_phone),
      },
      company_name: { required },
      company_business_id: { required },
    }
    return {
      ...useFormValidator(form, rules),
      countries,
      positions,
    }
  },
}
</script>
