<template>
  <nav aria-label="Progress">
    <ol class="flex items-center justify-center">
      <step-item
        v-for="(step, index) in steps"
        :key="index"
        :data="step"
        :state="getState(index)"
      />
    </ol>
  </nav>
</template>

<script>
import StepItem from "./StepItem.vue"
export default {
  name: "Steps",
  components: {
    StepItem,
  },
  props: {
    current: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      steps: [
        {
          name: "Your project",
          url: "/project-request/step1",
        },
        {
          name: "General information",
          url: "/project-request/step2",
        },
        {
          name: "Confirmation",
          url: "/project-request/step3",
        },
      ],
    }
  },
  methods: {
    getState(index) {
      const realIndex = index + 1
      return realIndex < parseInt(this.current)
        ? "previous"
        : parseInt(this.current) == realIndex
        ? "current"
        : realIndex == this.steps.length
        ? "last"
        : "next"
    },
  },
}
</script>
