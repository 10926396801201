<template>
  <form
    ref="formRef"
    :action="actionUrl"
    method="POST"
    @submit="submit"
  >
    <alert-error
      v-if="hasServerErrors"
      :details="errorMessages"
      class="mb-6"
    />
    <slot />
    <slot name="form" />
  </form>
</template>

<script>
import { computed, provide, reactive } from "vue"
import AlertError from "@/shared/AlertError"

export default {
  name: "TServerForm",
  components: {
    AlertError,
  },
  props: {
    // errors coming from server
    serverErrors: {
      type: [Object, Array, String],
      default: "{}",
    },
    // define which fields to render as form errors on top
    fieldsFormErrors: {
      type: Array,
      default: () => [],
    },
    actionUrl: {
      type: String,
      required: true,
    },
    validateForm: {
      type: Function,
      required: true,
    },
  },
  emits: ["submit"],
  setup (props) {
    const errors = reactive(JSON.parse(props.serverErrors))
    const hasServerErrors = computed(() => Array.isArray(errors)  ? errors.length > 0 : Object.keys(errors).length > 0)

    const errorMessages = computed(() => {
      if (hasServerErrors.value) {
        if (Array.isArray(errors)) {
          return errors
        } else if ("__all__" in errors) {
          return errors["__all__"].map(err => err.message)
        } else if (Object.keys(errors).length === 1) {
          return "There is one form error"
        } else {
          return `There are ${Object.keys(errors).length} form errors`
        }
      }
      return ""
    })

    // Individual field errors
    const hasServerFieldErrors = (name) => {
      return name in errors
    }
    //
    const serverFieldErrors = (name) => {
      return hasServerFieldErrors(name) ? errors[name].map(err => err.message) : []
    }

    provide("hasServerFieldErrors", hasServerFieldErrors)
    provide("serverFieldErrors", serverFieldErrors)


    return {
      hasServerErrors,
      errorMessages,
    }
  },
  methods: {
    submit (event) {
      if (!this.validateForm()) {
        event.preventDefault()
      } else {
        return true
      }
    },
  },
}
</script>
