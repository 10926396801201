<template>
  <t-server-form
    :action-url="actionUrl"
    :fields-form-errors="['email']"
    :server-errors="serverErrors"
    :validate-form="validateForm"
  >
    <slot />
    <template #form>
      <t-input
        v-model="form.email"
        label="E-mail"
        type="email"
        name="email"
        :validator="v$.email"
      />

      <div class="mt-6">
        <span class="block w-full rounded-md shadow-sm">
          <button
            type="submit"
            class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
          >
            Reset my password
          </button>
        </span>
      </div>
    </template>
  </t-server-form>
  <div class="relative flex justify-center mt-6 text-sm leading-5">
    <p class="px-2 text-gray-500">
      Fill in your e-mail address and we will send an e-mail to reset your password.
    </p>
  </div>
</template>

<script>
import TServerForm from "@/shared/ui/TServerForm"
import TInput from "@/shared/ui/TInput"
import { email } from "@vuelidate/validators"
import { required, useFormValidator } from "@/utils/validators"

export default {
  name: "PasswordReset",
  components: {
    TServerForm,
    TInput,
  },
  props: {
    serverErrors: {
      type: String,
      default: "",
    },
    actionUrl: {
      type: String,
      default: "",
    },
  },
  setup () {
    return {
      ...useFormValidator({ email: "" }, { email: { required, email }}),
    }
  },
}
</script>
