<template>
  <teleport to="body">
    <vue-final-modal
      :model-value="modelValue"
      :name="name"
      classes="modal-container"
      @update:model-value="$emit('update:modelValue', $event)"
      @closed="$emit('closed', $event)"
    >
      <div
        class="inline-block px-4 pt-5 pb-4 text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <!-- Close button -->
        <div
          v-if="closable"
          class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block"
        >
          <button
            type="button"
            class="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="close"
          >
            <span class="sr-only">Close</span>
            <icon
              name="x"
              class="w-6 h-6"
            />
          </button>
        </div>
        <!-- Content -->
        <slot>
          <div :class="!centered && 'sm:flex sm:items-start'">
            <div
              v-if="icon"
              class="flex items-center justify-center w-12 h-12 mx-auto rounded-full"
              :class="{'flex-shrink-0 sm:mx-0 sm:h-10 sm:w-10' : !centered,
                       'bg-red-100': variant === 'error',
                       'bg-green-100': variant === 'success'}"
            >
              <icon
                :name="iconName"
                :class="iconClass"
              />
            </div>
            <div
              class="mt-3 text-center"
              :class="centered? 'sm:mt-5' : 'sm:mt-0 sm:ml-4 sm:text-left'"
            >
              <h3
                id="modal-headline"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                {{ title }}
              </h3>
              <div
                v-if="content"
                class="mt-2"
              >
                <p class="text-sm text-gray-500">
                  {{ content }}
                </p>
              </div>
            </div>
            <div>
              <slot name="subcontent" />
            </div>
          </div>
        </slot>
        <!-- Footer -->
        <slot
          :close="close"
          name="footer"
        >
          <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <a-button
              v-if="primary"
              :variant="variant == 'error' ? 'danger.primary': 'secondary'"
              :loading="primaryLoading"
              :disabled="primaryDisabled"
              class="justify-center w-full"
              :class="[wide ? secondary ? 'sm:w-1/2' : '' : 'sm:w-auto', secondary && 'sm:ml-3']"
              @click="$emit('primary')"
            >
              {{ primary }}
            </a-button>
            <a-button
              v-if="secondary"
              class="justify-center w-full mt-3 sm:mt-0"
              :class="wide ? secondary ? 'sm:w-1/2' : '' : 'sm:w-auto'"
              @click="$emit('secondary')"
            >
              {{ secondary }}
            </a-button>
          </div>
        </slot>
      </div>
    </vue-final-modal>
  </teleport>
</template>

<script>
export default {
  name: "Modal",
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    closable: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: null,
      validator: function (value) {
        return (
          value === null ||
          ["error", "success"].indexOf(value) !== -1
        )
      },
    },
    centered: {
      type: Boolean,
      default: true,
    },
    primary: {
      type: [Boolean, String],
      default: "Ok",
    },
    secondary: {
      type: [Boolean, String],
      default: "Cancel",
    },
    primaryLoading: {
      type: Boolean,
      default: false,
    },
    primaryDisabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Modal title",
    },
    content: {
      type: String,
      default: "",
    },
    wide: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "primary", "secondary", "closed"],
  data() {
    return {
      open: this.modelValue,
    }
  },
  computed: {
    iconName () {
      switch (this.variant) {
        case "success":
          return "check"
        case "error":
          return "exclamation"
        default:
          return null
      }
    },
    iconClass () {
      switch (this.variant) {
        case "success":
          return "w-6 h-6 text-green-600"
        case "error":
          return "w-6 h-6 text-red-600"
        default:
          return null
      }
    },
  },
  methods: {
    close () {
      this.$emit("update:modelValue", false)
    },
  },
}
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
