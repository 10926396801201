const requiredClass = "text-primary-variant"

export default {
  alert: {
    container: {
      base:
        "rounded-md p-4 flex",
      info: "bg-blue-100",
      success: "bg-green-50",
      warning: "bg-yellow-50",
      error: "bg-red-50",
    },
    leadingIcon: {
      base: "h-5 w-5",
      info: "text-blue-400",
      success: "text-green-400",
      warning: "text-yellow-400",
      error: "text-red-400",
    },
    content: {
      base: "text-sm font-medium",
      info: "text-blue-700",
      success: "text-success",
      warning: "text-yellow-700",
      error: "text-red-700",
    },
    close: {
      base: "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2",
      info: "bg-blue-100 text-blue-500 hover:bg-blue-200 focus:ring-offset-blue-50 focus:ring-blue-600",
      success: "bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600",
      warning: "bg-yellow-50 text-yellow-500 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-600",
      error: "bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600",
    },
  },
  button: {
    base: "items-center px-4 py-2 border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2",
    block: "block w-full",
    inline: "inline-flex",
    variants: {
      root: {
        fill: "",
        outline: "",
        link: "",
        disabled: "opacity-50 cursor-not-allowed",
      },
      default: {
        fill: "border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-primary-variant",
        outline: "",
        link: "",
        disabled: "bg-gray-300 hover:bg-gray-300",
      },
      primary: {
        fill: "border-transparent text-white bg-primary-variant hover:bg-indigo-900 focus:ring-indigo-600",
        // fill: "border-transparent text-white bg-indigo-600 hover:bg-indigo-700",
        outline:
          "",
        link: "",
        disabled: "bg-gray-400 hover:bg-gray-400",
      },
      secondary: {
        fill: "border-transparent text-white bg-secondary hover:bg-blue-500 focus:ring-blue-400",
        outline: "",
        link: "",
        disabled: "bg-blue-50 text-blue-400 hover:bg-blue-50",
      },
      "danger.primary": {
        fill: "border-transparent bg-red-600 text-white hover:bg-red-700 focus:ring-red-500",
        outline: "",
        link: "",
        disabled: "bg-red-50 hover:bg-red-50",
      },
      "danger.secondary": {
        fill: "border-transparent text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500",
        outline: "",
        link: "",
        disabled: "bg-red-50 hover:bg-red-50",
      },
      success: {
        fill: "border-transparent text-white bg-success hover:bg-green-600 focus:ring-green-500",
        outline: "",
        link: "",
        disabled: "bg-green-50 hover:bg-green-50",
      },
    },
    // icon classes
    leadingIcon: "h-5 w-5",
    trailingIcon: " h-5 w-5",
    iconMarginLeft: "-ml-1 mr-2 ",
    iconMarginright: "ml-2 -mr-1",
  },
  icon: {
    base: "fill-current w-4 h-4",
    loading: "spin",
  },
  input: {
    root: "",
    container: "relative rounded-md shadow-sm",
    required: requiredClass,
    input: {
      root: "shadow-sm block w-full sm:text-sm rounded-md placeholder-gray-300",
      base: "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300",
      leading: "pl-10",
      trailing: "pr-10",
      error:
        "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500",
      warning: "border-yellow-300 text-yellow-900 placeholder-yellow-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500",
      info: "border-gray-300",
      success: "border-gray-300",
      disabled: "text-gray-500 bg-gray-100 border-gray-300",
      readonly: "bg-gray-200 border-gray-300",
    },
    label: {
      root: "block text-sm font-medium mb-1",
      base: "text-gray-700",
      error: "text-red-600",
      warning: "text-yellow-600",
      info: "text-blue-600",
      success: "",
      disabled: "",
    },
    leadingIcon: {
      root: "h-5 w-5",
      base: "text-gray-400",
      container:
        "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none",
      error: "text-red-500",
      warning: "text-yellow-500",
      info: "text-blue-500",
      success: "",
      disabled: "text-gray-300",
    },
    trailingIcon: {
      root: "h-5 w-5",
      base: "text-gray-400",
      container: "absolute inset-y-0 right-0 pr-3 flex items-center",
      error: "text-red-500",
      warning: "text-yellow-500",
      info: "text-blue-500",
      success: "text-green-600",
      disabled: "text-gray-300",
    },
    help: {
      root: "mt-2 text-sm",
      base: "text-gray-500",
      error: "text-red-600",
      warning: "text-yellow-500",
      info: "text-blue-600",
      success: "text-green-500",
    },
  },
  textarea: {
    root: "",
    container: "relative rounded-md shadow-sm",
    required: requiredClass,
    textarea: {
      root: "shadow-sm block w-full sm:text-sm rounded-md leading-tight placeholder-gray-300",
      base: "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300",
      leading: "pl-10",
      trailing: "pr-10",
      error:
        "pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500",
      warning: "",
      info: "border-gray-300",
      success: "border-gray-300",
      disabled: "text-gray-500 bg-gray-100 border-gray-300",
      readonly: "bg-gray-200",
    },
    label: {
      root: "block text-sm font-medium mb-1",
      base: "text-gray-700",
      error: "text-red-600",
      warning: "",
      info: "",
      success: "",
      disabled: "",
    },
    leadingIcon: {
      root: "h-5 w-5",
      base: "text-gray-400",
      container:
        "absolute left-0 top-0 pt-3 pl-3 flex items-center pointer-events-none",
      error: "text-red-500",
      warning: "",
      info: "",
      success: "",
      disabled: "text-gray-300",
    },
    trailingIcon: {
      root: "h-5 w-5",
      base: "text-gray-400",
      container: "absolute pt-3 top-0 right-0 pr-3 flex items-center",
      error: "text-red-500",
      warning: "",
      info: "",
      success: "text-green-600",
      disabled: "text-gray-300",
    },
    help: {
      root: "mt-2 text-sm",
      base: "text-gray-500",
      error: "text-red-600",
      warning: "text-yellow-800",
      info: "",
      success: "",
    },
  },
  switch: {
    root: "flex items-center",
    required: requiredClass,
    label: {
      base: "font-medium text-sm text-gray-700",
      error: "text-red-600",
      warning: "",
      info: "",
      success: "",
      disabled: "text-gray-300",
    },
    back: {
      base:
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
      checked: "bg-green-600",
      unchecked: "bg-gray-300",
      disabled: "opacity-50",
    },
    front: {
      base:
        "inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
      checked: "translate-x-5",
      unchecked: "translate-x-0",
      disabled: "bg-gray-100",
    },
    help: {
      root: "mt-2 text-sm",
      base: "text-gray-500",
      error: "text-red-600",
      warning: "text-yellow-800",
      info: "",
      success: "",
    },
  },
  select: {
    root: "",
    container: "relative rounded-md shadow-sm",
    required: requiredClass,
    select: {
      root: "shadow-sm block w-full sm:text-sm rounded-md placeholder-gray-300",
      base: "focus:ring-indigo-500 focus:border-indigo-500 border-gray-300",
      leading: "pl-10",
      trailing: "pr-20",
      base_multiple: "block w-full",
      error:
        "border border-red-500 text-red-600 placeholder-red-300 focus:border-red-500 focus:shadow-outline-red",
      warning: "",
      info: "border-gray-300",
      success: "border-gray-300",
      disabled: "text-gray-500",
      readonly: "bg-gray-200",
    },
    label: {
      root: "block text-sm text-left font-medium leading-5 mb-1",
      base: "text-gray-700",
      error: "text-red-600",
      warning: "",
      info: "text-gray-600",
      success: "text-gray-600",
      disabled: "text-gray-500",
    },
    leadingIcon: {
      root: "h-5 w-5",
      base: "text-gray-400",
      container:
        "absolute inset-y-0 left-0 flex items-center pointer-events-none px-2",
      error: "text-red-600",
      warning: "",
      info: "",
      success: "",
      disabled: "text-gray-500",
    },
    trailingIcon: {
      root: "h-5 w-5",
      base: "text-gray-400",
      container: "absolute inset-y-0 right-0 pr-10 flex items-center",
      error: "text-red-500",
      warning: "",
      info: "",
      success: "text-green-600",
      disabled: "text-gray-500",
    },
    help: {
      root: "block text-sm mt-1 text-left font-medium leading-5",
      base: "text-gray-500",
      error: "text-red-600",
      warning: "text-yellow-800",
      info: "text-gray-500",
      success: "",
    },
  },
  checkbox: {
    root: "",
    container: "flex items-center",
    required: requiredClass,
    input: {
      root: "h-4 w-4 border-gray-300 rounded",
      base: "focus:ring-indigo-500 text-indigo-600",
      error: "text-red-600",
      warning: "",
      info: "",
      success: "text-green-400",
      disabled: "text-gray-500",
      readonly: "",
    },
    label: {
      root: "block text-sm font-medium",
      base: "text-gray-700",
      error: "text-red-600",
      warning: "",
      info: "",
      success: "",
      disabled: "",
    },
    text: {
      root: "ml-2 block text-sm",
      base: "text-gray-900",
      error: "text-red-600",
      warning: "",
      info: "",
      success: "",
      disabled: "",
    },
    help: {
      root: "mt-2 text-sm",
      base: "text-gray-600",
      error: "text-red-600",
      warning: "text-yellow-800",
      info: "",
      success: "text-green-400",
    },
  },
  radio: {
    root: "",
    container: "flex items-center",
    required: requiredClass,
    input: {
      root: "h-4 w-4 border-gray-300",
      base: "focus:ring-indigo-500 text-indigo-600",
      error: "text-red-600",
      warning: "",
      info: "",
      success: "",
      disabled: "text-gray-500",
      readonly: "",
    },
    label: {
      root: "block text-sm font-medium",
      base: "text-gray-700",
      error: "text-red-600",
      warning: "",
      info: "",
      success: "",
      disabled: "",
    },
    text: {
      root: "ml-2 block text-sm",
      base: "text-gray-900",
      error: "text-red-600",
      warning: "",
      info: "",
      success: "",
      disabled: "",
    },
    help: {
      root: "mt-2 text-sm",
      base: "text-gray-600",
      error: "text-red-600",
      warning: "text-yellow-800",
      info: "",
      success: "",
    },
  },
  // used for checkbox or radio group
  group: {
    root: "",
    container: "",
    container_vertical: "flex-col",
    container_horizontal: "flex items-center",
    required: requiredClass,
    label: {
      root: "block text-sm font-medium",
      base: "text-gray-700",
      error: "text-red-600",
      warning: "",
      info: "",
      success: "",
      disabled: "",
    },
    help: {
      root: "mt-2 text-sm",
      base: "text-gray-600",
      error: "text-red-600",
      warning: "text-yellow-800",
      info: "",
      success: "text-green-400",
    },
  },
}
