import useVuelidate from "@vuelidate/core"
import { required as requiredR, minLength as minLengthR, maxLength as maxLengthR, helpers } from "@vuelidate/validators"
import { computed, inject } from "vue"
import { useForm } from "@inertiajs/inertia-vue3"

export const useFieldValidator = (validator, name) => {
  const _hasServerFieldErrors = inject("hasServerFieldErrors", () => false)
  const _serverFieldErrors = inject("serverFieldErrors", () => [])
  // make injected methods reactive
  const hasServerFieldErrors = computed(() => _hasServerFieldErrors(validator?.$path || name))
  const serverFieldErrors = computed(() => _serverFieldErrors(validator?.$path || name))

  const hasError = computed(() => {
    if(validator == null && !name) {
      return false
    } else if (validator == null && name) {
      return hasServerFieldErrors.value
    } else {
      return !!validator.$error || (!validator.$dirty && hasServerFieldErrors.value)
    }
  })
  const errors = computed(() => {
    if(validator == null && !name) {
      return []
    } else if (validator == null && name) {
      return serverFieldErrors.value
    } else {
      return validator.$dirty ? validator?.$errors.map(err => err.$message) : serverFieldErrors.value
    }
  })
  const firstError = computed(() => errors.value.length > 0 ? errors.value[0] : "")
  return {
    hasServerFieldErrors,
    serverFieldErrors,
    hasError,
    errors,
    firstError,
  }
}

export const useFormValidator = (formObject, rules) => {
  const form = useForm(formObject)
  const v$ = useVuelidate(rules, form)
  const isFormValid = computed(() => !v$.value.$error)
  const validateForm = () => {
    // run validation
    v$.value.$touch()
    return isFormValid.value
  }
  return {
    form,
    v$,
    isFormValid,
    validateForm,
  }
}

export const useChildFormValidator = (context, formObject, rules) => {
  const { form, validateForm, v$, isFormValid } = useFormValidator(formObject, rules)
  const submitForm = () => {
    if(validateForm()) {
      context.emit("submit", form, v$.value)
    }
  }
  return {
    form,
    v$,
    isFormValid,
    validateForm,
    submitForm,
  }
}

export const validatorProps = {
  validator: {
    type: Object,
    default: null,
  },
}

/* Customize form error messages */
const required = helpers.withMessage("The field is required", requiredR)
const minLength = (val) => helpers.withMessage(({ $params }) => `The field has to be longer than ${$params.min} characters`, minLengthR(val))
const maxLength = (val) => helpers.withMessage(({ $params }) => `The field has to be shorter than ${$params.max} characters`, maxLengthR(val))

const passwordRegex = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*[@#$^!,.<>;\/\\"=:'\+\-_§€£`*%*?&\]\[\(\)]).*$/gm
const passwordError = "The password must contains at least one special character, one upper letter, one lowercase letter and one digit."
const passwordRule = (val) => val.match(passwordRegex) && val.length >= 10
const password = helpers.withMessage(passwordError, passwordRule)

export {
  required,
  minLength,
  maxLength,
  password,
}
