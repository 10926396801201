<template>
  <div
    class="flex flex-col justify-center min-h-screen py-12 sm:px-6 lg:px-8"
    :class="background"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "FullPageLayout",
  props: {
    background: {
      type: String,
      default: "bg-primary-variant",
    },
  },
}
</script>
