<template>
  <component
    :is="name"
    :class="loading && 'spin'"
  />
</template>

<script>
import annotation from "../icons/annotation.vue"
import arrowCircleRight from "../icons/arrow-circle-right.vue"
import arrowCircleLeft from "../icons/arrow-circle-left.vue"
import arrowNarrowLeft from "../icons/arrow-narrow-left.vue"
import arrowNarrowRight from "../icons/arrow-narrow-right.vue"
import ban from "../icons/ban.vue"
import bell from "../icons/bell.vue"
import briefcase from "../icons/briefcase.vue"
import calendar from "../icons/calendar.vue"
import checkCircle from "../icons/check-circle.vue"
import check from "../icons/check.vue"
import chevronDown from "../icons/chevron-down.vue"
import chevronLeft from "../icons/chevron-left.vue"
import chevronRight from "../icons/chevron-right.vue"
import chevronUp from "../icons/chevron-up.vue"
import clipboardCheck from "../icons/clipboard-check.vue"
import clipboardList from "../icons/clipboard-list.vue"
import clock from "../icons/clock.vue"
import cog from "../icons/cog.vue"
import counter from "../icons/counter.vue"
import documentAdd from "../icons/document-add.vue"
import documentText from "../icons/document-text.vue"
import downloadDocument from "../icons/download-document.vue"
import duplicate from "../icons/duplicate.vue"
import equipment from "../icons/equipment.vue"
import exclamation from "../icons/exclamation.vue"
import eye from "../icons/eye.vue"
import eyeOff from "../icons/eye-off.vue"
import {default as filterIcon } from "../icons/filter.vue"
import flag from "../icons/flag.vue"
import home from "../icons/home.vue"
import informationCircle from "../icons/information-circle.vue"
import key from "../icons/key.vue"
import {default as linkIcon} from "../icons/link.vue"
import loading from "../icons/loading.vue"
import locationMarker from "../icons/location-marker.vue"
import lockClosed from "../icons/lock-closed.vue"
import logout from "../icons/logout.vue"
import mail from "../icons/mail.vue"
import menuAlt1 from "../icons/menu-alt-1.vue"
import minus from "../icons/minus.vue"
import officeBuilding from "../icons/office-building.vue"
import pencilAlt from "../icons/pencil-alt.vue"
import phone from "../icons/phone.vue"
import plus from "../icons/plus.vue"
import reply from "../icons/reply.vue"
import save from "../icons/save.vue"
import search from "../icons/search.vue"
import staff from "../icons/staff.vue"
import support from "../icons/support.vue"
import trash from "../icons/trash.vue"
import uploadDocument from "../icons/upload-document.vue"
import userGroup from "../icons/user-group"
import userCircle from "../icons/user-circle"
import user from "../icons/user"
import userAdd from "../icons/user-add"
import users from "../icons/users"
import vessel from "../icons/vessel.vue"
import cloud from "../icons/cloud.vue"
import xCircle from "../icons/x-circle.vue"
import x from "../icons/x.vue"

export default {
  name: "Icon",
  components: {
    annotation,
    arrowCircleRight,
    arrowCircleLeft,
    arrowNarrowLeft,
    arrowNarrowRight,
    ban,
    bell,
    briefcase,
    calendar,
    checkCircle,
    check,
    chevronDown,
    chevronLeft,
    chevronRight,
    chevronUp,
    clipboardCheck,
    clipboardList,
    clock,
    cog,
    counter,
    documentAdd,
    documentText,
    downloadDocument,
    duplicate,
    exclamation,
    equipment,
    eyeOff,
    eye,
    filterIcon,
    flag,
    home,
    informationCircle,
    key,
    linkIcon,
    loading,
    locationMarker,
    lockClosed,
    logout,
    mail,
    menuAlt1,
    minus,
    officeBuilding,
    pencilAlt,
    phone,
    plus,
    reply,
    save,
    search,
    staff,
    support,
    trash,
    uploadDocument,
    user,
    userAdd,
    userCircle,
    userGroup,
    users,
    vessel,
    cloud,
    xCircle,
    x,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  // computed: {
  //   iconComponent () {
  //     return IconAlert
  //     // return () => import(/* webpackChunkName: "icons-" */`../icons/${this.name}.vue`)
  //   },
  // },
}
</script>

<style scoped>
@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spin {
  animation: loading-spinner 1s linear infinite;
}
</style>
