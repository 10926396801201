<template>
  <div>
    <label
      v-if="label || $slots.label"
      :for="$attrs.name"
      class="block text-sm font-medium text-gray-700"
    >
      <slot name="label">{{ label }}</slot>
    </label>
    <div class="mt-1 relative rounded-md shadow-sm">
      <input
        :id="$attrs.id"
        ref="inputElement"
        :name="`display_${$attrs.name}`"
        :placeholder="$attrs.placeholder"
        role="input"
        type="tel"
        :disabled="disabled"
        class="shadow-sm block w-full sm:text-sm rounded-md"
        :class="{
          'pl-10': !!leadingIcon,
          'pr-10': (hasError || trailingIcon),
          'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500': !(hasError || trailingIcon),
          'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': (hasError || trailingIcon),
          'text-gray-500 bg-gray-100 border-gray-300': disabled,
        }"
        @change="onChange"
      >
      <div
        v-if="trailingIcon"
        class="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none"
      >
        <!-- Trailing icon when error -->
        <svg
          class="h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div
        v-if="hasError"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5 text-red-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
    <p
      class="mt-1 text-sm"
      :class="{
        'text-gray-500': !hasError,
        'text-red-600': hasError
      }"
    >
      {{ hasError ? errorMessage : help }}
    </p>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue"
import { inputProps, inputIconProps } from "vue-batteries"
import { useFieldValidator, validatorProps } from "@/utils/validators"
import "intl-tel-input/build/css/intlTelInput.css"
import intlTelInput from "intl-tel-input"
export default {
  name: "TPhoneInput",
  props: {
    modelValue: {
      type: [Number, String],
      default: "",
    },
    ...inputProps,
    ...inputIconProps,
    ...validatorProps,
    defaultCountries: {
      type: Array,
      default: () => ["fr", "en"],
    },
  },
  emits: ["update:modelValue"],
  setup (props, context) {
    const inputElement = ref(null)
    const iti = ref()
    const isPhoneValid = ref(true)
    const itiError = ref("")

    onMounted(() => {
      iti.value = intlTelInput(inputElement.value, {
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.min.js",
        preferredCountries: props.defaultCountries,
        hiddenInput: context.attrs.name,
      })
      iti.value.setNumber(props.modelValue)
    })
    const onChange = () => {
      if (iti.value.getNumber()) {
        isPhoneValid.value = iti.value.isValidNumber()
        if (!isPhoneValid.value) {
          const countryCode = iti.value.getSelectedCountryData().iso2
          itiError.value = `Invalid phone number for ${countryCode.toUpperCase()} country`
        }
      } else {
        isPhoneValid.value = true
      }
      context.emit("update:modelValue", iti.value.getNumber())
      iti.value.setNumber(iti.value.telInput.value)
    }

    const { hasError: hasValidationError, firstError } = useFieldValidator(props.validator, context.attrs.name)
    const hasError = computed(() => hasValidationError.value || !isPhoneValid.value)
    const errorMessage = computed(() => firstError.value || itiError.value)
    return {
      iti,
      inputElement,
      hasError,
      errorMessage,
      onChange,
    }
  },
}
</script>
