import { createApp } from "vue"
import VueBatteries from "vue-batteries"
import Icon from "@/shared/ui/Icon.vue"
import theme from "@/theme"
import VueFinalModal from "vue-final-modal"

createApp({})
  // layouts
  .component("FullPageLayout", require("../layouts/FullPageLayout.vue").default)
  .component("FullPageCardLayout", require("../layouts/FullPageCardLayout.vue").default)
  // pages
  .component("Login", require("./components/Login.vue").default)
  .component("ClientStep1", require("./components/signup_client/Step1.vue").default)
  .component("ClientStep2", require("./components/signup_client/Step2.vue").default)
  .component("ClientStep3", require("./components/signup_client/Step3.vue").default)
  .component("SignUpProfessional", require("./components/SignUpProfessional.vue").default)
  .component("PasswordReset", require("./components/PasswordReset.vue").default)
  .component("PasswordResetForm", require("./components/PasswordResetForm.vue").default)
  // components
  .component("ProfileCompletion", require("./components/ProfileCompletion.vue").default)
  .component("CardMessage", require("../shared/CardMessage.vue").default)
  .component("Steps", require("../shared/Steps.vue").default)
  .component("Icon", Icon)
  .use(VueFinalModal(), {key: "$modal"})
  .use(VueBatteries, {
    prefix: "a",
    theme,
    iconComponent: "Icon",
    statusIcons: {
      error: "x-circle",
      success: "check-circle",
      info: "information-circle",
      warning: "exclamation",
    },
  })
  .mount("#app")
