<template>
  <t-server-form
    :action-url="actionUrl"
    :server-errors="serverErrors"
    :validate-form="validateForm"
  >
    <slot />
    <template #form>
      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl">
        <!-- project data -->
        <div class="bg-white shadow sm:rounded-lg mb-2">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Project Information
            </h3>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Name
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ project.name }}
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Onshore location
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ project.on_location }}
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Offshore Location
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ project.off_location }}
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Description
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ project.description }}
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Expected deliverables
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ project.expected_deliverables }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <!-- personal data -->
        <div class="bg-white shadow sm:rounded-lg mb-2">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Personal Information
            </h3>
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Full name
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ client.first_name }} {{ client.last_name }}
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  E-mail
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ client.email }}
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Mobile phone
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ client.mobile_phone || "N/A" }}
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Land phone
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ client.land_phone || "N/A" }}
                </dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                  Address
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ client.address }}, {{ client.nationality }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <!-- old -->
        <div class="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
          <t-checkbox
            id="conditions"
            v-model="form.conditions"
            name="conditions"
            :validator="v$.conditions"
          >
            I agree to the use of SAAS services
          </t-checkbox>
          <div class="flex items-center justify-between">
            <a
              :href="previousUrl"
              class="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 place place-self-center"
            >
              Make changes
            </a>
            <a-button
              type="submit"
              variant="primary"
              leading-icon="mail"
            >
              Send request
            </a-button>
          </div>
        </div>
      </div>
    </template>
  </t-server-form>
</template>

<script>
import TServerForm from "@/shared/ui/TServerForm"
import TCheckbox from "@/shared/ui/TCheckbox"
import { useFormValidator } from "@/utils/validators"
import { sameAs, helpers } from "@vuelidate/validators"
export default {
  name: "SignUpClient",
  components: {
    TServerForm,
    TCheckbox,
  },
  props: {
    data: {
      type: String,
      default: "",
    },
    clientData: {
      type: String,
      default: "",
    },
    actionUrl: {
      type: String,
      default: "",
    },
    previousUrl: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { project, client } = JSON.parse(props.data)
    const form = {
      conditions: false,
    }
    const rules = {
      conditions: { sameAs: helpers.withMessage("You should check this box", sameAs(true)) },
    }
    return {
      project,
      client,
      ...useFormValidator(form, rules),
    }
  },
}
</script>
