<template>
  <full-page-layout>
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="w-auto h-32 mx-auto"
        src="/static/pastille-SAAS-bleu.png"
        alt="Workflow"
      >
      <h2 class="mt-6 text-3xl font-extrabold leading-9 text-center text-white">
        {{ title }}
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10">
        <slot />
      </div>
    </div>
  </full-page-layout>
</template>

<script>
import FullPageLayout from "./FullPageLayout.vue"
export default {
  name: "FullPageCardLayout",
  components: {
    FullPageLayout,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
}
</script>
