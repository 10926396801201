<template>
  <li
    class="relative"
    :class="state != 'last' && 'pr-8 sm:pr-20'"
  >
    <div
      class="absolute inset-0 flex items-center"
      aria-hidden="true"
    >
      <div
        class="h-0.5 w-full bg-indigo-600"
        :class="
          state == 'previous'
            ? 'h-0.5 w-full bg-indigo-600'
            : 'h-0.5 w-full bg-gray-200'
        "
      />
    </div>

    <a
      :href="data.url"
      class="relative w-8 h-8 flex items-center justify-center rounded-full"
      :class="{
        'bg-indigo-600 hover:bg-indigo-900': state == 'previous',
        'bg-white border-indigo-600': state == 'current',
        'group bg-white border-2 border-gray-300  hover:border-gray-400':
          state == 'next' || state == 'last',
      }"
    >
      <!-- previous -->
      <svg
        v-if="state == 'previous'"
        class="w-5 h-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
          clip-rule="evenodd"
        />
      </svg>
      <!-- current -->
      <span
        v-else-if="state == 'current'"
        class="h-2.5 w-2.5 bg-indigo-600 rounded-full"
        aria-hidden="true"
      />
      <!-- next -->
      <span
        v-else
        class="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300"
        aria-hidden="true"
      />
      <!-- <span class="text-sm mt-8 text-primary-variant">{{ data.name }}</span> -->
    </a>
  </li>
</template>

<script>
export default {
  name: "StepItem",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    state: {
      type: String,
      default: "current",
    },
  },
}
</script>
