<template>
  <t-server-form
    :action-url="actionUrl"
    :server-errors="serverErrors"
    :validate-form="validateForm"
  >
    <slot />
    <template #form>
      <t-input
        id="login"
        v-model="form.login"
        :validator="v$.login"
        type="email"
        name="login"
        label="E-mail"
        class="mt-6"
      />
      <t-input
        id="password"
        v-model="form.password"
        :validator="v$.password"
        type="password"
        name="password"
        label="Password"
        class="mt-6"
      />

      <div class="flex items-center justify-end mt-6">
        <div class="text-sm leading-5">
          <a
            :href="resetUrl"
            class="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:outline-none focus:underline"
          >
            Forgot your password ?
          </a>
        </div>
      </div>

      <div class="mt-6">
        <span class="block w-full rounded-md shadow-sm">
          <button
            type="submit"
            class="flex justify-center w-full px-4 py-2 text-sm font-medium text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
          >
            Se connecter
          </button>
        </span>
      </div>
      <div class="mt-6">
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center text-sm leading-5">
            <a
              :href="signupUrlProfessional"
              class="flex items-center px-2 text-gray-500 bg-white cursor-pointer hover:text-indigo-600"
            >
              <svg
                class="w-5 h-5 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              /></svg>
              I am a professional
            </a>
          </div>
        </div>
      </div>
      <div class="mt-6">
        <div class="relative">
          <div class="absolute inset-0 flex items-center">
            <div class="w-full border-t border-gray-300" />
          </div>
          <div class="relative flex justify-center text-sm leading-5">
            <a
              :href="signupUrlClient"
              class="flex items-center px-2 text-gray-500 bg-white cursor-pointer hover:text-indigo-600"
            >
              <svg
                class="w-5 h-5 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              ><path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              /></svg>
              I have a project !
            </a>
          </div>
        </div>
      </div>
    </template>
  </t-server-form>
</template>

<script>
import TServerForm from "@/shared/ui/TServerForm"
import TInput from "@/shared/ui/TInput"
import { email } from "@vuelidate/validators"
import { required, useFormValidator } from "@/utils/validators"
export default {
  name: "Login",
  components: {
    TServerForm,
    TInput,
  },
  props: {
    serverErrors: {
      type: String,
      default: "",
    },
    actionUrl: {
      type: String,
      default: "",
    },
    signupUrlClient: {
      type: String,
      default: "",
    },
    signupUrlProfessional: {
      type: String,
      default: "",
    },
    resetUrl: {
      type: String,
      default: "",
    },
  },
  setup () {
    const form = {
      login: "",
      password: "",
      remember: "",
      ...JSON.parse(document.getElementById("form_data").textContent),
    }
    const rules = {
      login: { required, email },
      password: { required },
    }
    return {
      ...useFormValidator(form, rules),
    }
  },
  // mounted () {
  // TODO: load initial values into form
  //   this.form = JSON.parse(document.getElementById("form_data").textContent)
  // },
}
</script>
