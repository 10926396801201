<template>
  <svg
    class="w-6 h-6"
    fill="currentColor"
    stroke="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  ><path
    d="M12 4V2A10 10 0 002 12h2a8 8 0 018-8z"
  />
  </svg>
</template>
